import { BaseMapElement } from "../base"
import { getMagnitudeEValue, magColors } from "../../_utils"
import { ICoord } from "@types"

interface IAlarmAnimation {
  coord: ICoord
  magnitude: number
}

export class AlarmAnimation extends BaseMapElement<IAlarmAnimation> {
  private magColor: string

  innerRing: HTMLElement
  outerRing: HTMLElement

  constructor(dataEntity: IAlarmAnimation) {
    super(dataEntity)

    this.magColor = magColors[getMagnitudeEValue(dataEntity.magnitude)]

    this.innerRing = this.createInnerRing()
    this.outerRing = this.createOuterRing()

    this.updateElementsColors()

    this._element.appendChild(this.innerRing)
    this._element.appendChild(this.outerRing)
  }

  protected createElement(): HTMLDivElement {
    const animationContainer = document.createElement("div")
    animationContainer.style.position = "absolute"
    animationContainer.style.width = "60px"
    animationContainer.style.height = "60px"

    return animationContainer
  }

  private createInnerRing(): HTMLDivElement {
    const innerRing = document.createElement("div")
    innerRing.style.position = "absolute"
    innerRing.style.width = "60px"
    innerRing.style.height = "60px"
    innerRing.style.borderRadius = "50%"
    innerRing.style.zIndex = "-1"
    innerRing.style.opacity = "0.5"

    innerRing.style.left = `calc(50% - ${innerRing.style.width} / 2)`
    innerRing.style.top = `calc(50% - ${innerRing.style.height} / 2)`

    const pulseDot = [{ transform: "scale(0.8)" }, { transform: "scale(1)" }, { transform: "scale(0.8)" }]

    const pulseDotTiming: KeyframeAnimationOptions = {
      easing: "cubic-bezier(0.455, 0.03, 0.515, 0.955)",
      duration: 3500,
      iterations: Infinity,
    }

    innerRing.animate(pulseDot, pulseDotTiming)

    return innerRing
  }

  private createOuterRing(): HTMLDivElement {
    const outerRing = document.createElement("div")
    outerRing.style.position = "absolute"
    outerRing.style.width = "60px"
    outerRing.style.height = "60px"
    outerRing.style.borderRadius = "50%"
    outerRing.style.opacity = "0"
    outerRing.style.zIndex = "-1"

    outerRing.style.left = `calc(50% - ${outerRing.style.width} / 2)`
    outerRing.style.top = `calc(50% - ${outerRing.style.height} / 2)`

    const pulseRing = [
      { transform: "scale(0.8)", opacity: "0" },
      { transform: "scale(1)", opacity: "0.5" },
      { transform: "scale(1.3)", opacity: "0" },
    ]

    const pulseRingTiming: KeyframeAnimationOptions = {
      easing: "cubic-bezier(0.455, 0.03, 0.515, 0.955)",
      duration: 3500,
      iterations: Infinity,
    }

    outerRing.animate(pulseRing, pulseRingTiming)

    return outerRing
  }

  private updateElementsColors(): void {
    this.innerRing.style.background = `radial-gradient(transparent, ${this.magColor})`
    this.innerRing.style.border = `1px solid ${this.magColor}`
    this.outerRing.style.border = `2px solid ${this.magColor}`
  }

  setMagnitude = (magnitude: number): void => {
    this._dataEntity.magnitude = magnitude
    this.magColor = magColors[getMagnitudeEValue(magnitude)]
    this.updateElementsColors()
  }
}
