import { BaseMapElementFactory } from "../base"
import { QuakeMarker } from "./_QuakeMarker"
import { AlarmAnimation } from "./_AlarmAnimation"
import { ELayers, ILocale, IQuake } from "@types"
import { getMagnitudeEValue } from "../../_utils"
import { TooltipController } from "../quake_marker_tooltip"
import { getTimeEValue } from "@utils"
import { FilterController } from "../../fragments"

type TPreventCb = (element: Element) => void

export class QuakeMarkerFactory extends BaseMapElementFactory<IQuake, QuakeMarker> {
  private overlay: Element | null = null
  private mouseOverlay: Element | null = null
  private preventCb: TPreventCb | null = null

  private alarmAnimations: Record<string, AlarmAnimation> = {}

  private tooltipController: TooltipController

  constructor(
    dataEntityList: IQuake[],
    getMapProjection: () => google.maps.MapCanvasProjection,
    private filterController: FilterController,
    l: ILocale
  ) {
    super(dataEntityList)

    this.tooltipController = new TooltipController(getMapProjection, l)
  }

  createElements = (overlay: Element, mouseOverlay: Element, preventCb: TPreventCb): void => {
    this.overlay = overlay
    this.mouseOverlay = mouseOverlay
    this.preventCb = preventCb

    this.dataEntityList.forEach((item) => {
      this.createQuakeMarker(item)
      this._filter(item)
    })
    this.mountElements()
  }

  private createQuakeMarker = (quake: IQuake): void => {
    this.elements[quake.id] = new QuakeMarker(quake, this.tooltipController.openTooltip)

    if (!quake.isActive) return

    this.alarmAnimations[quake.id] = new AlarmAnimation({ coord: quake.coord, magnitude: quake.magnitude })
  }

  mountElements = (): void => {
    this.dataEntityList.forEach(({ id }) => {
      this.alarmAnimations[id]?.mount(this.overlay as Element, this.preventCb as TPreventCb)
      this.elements[id].mount(this.mouseOverlay as Element, this.preventCb as TPreventCb)
    })

    this.tooltipController.mountTooltip(this.mouseOverlay as Element, this.preventCb as TPreventCb)
  }

  override calcPositions = (overlayProjection: google.maps.MapCanvasProjection): void => {
    this.dataEntityList.forEach(({ id }) => {
      this.elements[id].calcPosition(overlayProjection)
      this.alarmAnimations[id]?.calcPosition(overlayProjection)
    })

    this.tooltipController.calcTooltipPosition(overlayProjection)
  }

  filter = (): void => this._filter()

  //   TODO: мб удалять анимации, а не прятать
  private _filter(data?: IQuake): void {
    const setVisibility = (data: IQuake): void => {
      const isVisible = this.hasPassedFilters(data)

      this.elements[data.id].setVisible(isVisible)
      this.alarmAnimations[data.id]?.setVisible(data.isActive && isVisible)

      if (!isVisible && this.tooltipController.id === data.id) this.closeTooltip()
    }

    data !== undefined ? setVisibility(data) : this.dataEntityList.forEach(setVisibility)
  }

  private hasPassedFilters(quake: IQuake): boolean {
    if (!this.filterController.hasPassedFilters(ELayers.QUAKES)) return false

    const magVal = getMagnitudeEValue(quake.magnitude)
    const hasPassedMagFilter = this.filterController.hasPassedFilters(magVal)

    const timeVal = getTimeEValue(quake.time)
    const hasPassedTimeFilter = this.filterController.hasPassedFilters(timeVal)

    return hasPassedMagFilter && hasPassedTimeFilter
  }

  override dispose = (): void => {
    Object.values(this.elements).forEach((el) => el.dispose())
    Object.values(this.alarmAnimations).forEach((el) => el.dispose())
  }

  reset = (newData: IQuake[]): void => {
    this.closeTooltip()
    this.dispose()
    this.isVisible = true

    this.dataEntityList = newData
    this.alarmAnimations = {}
    this.elements = {}
  }

  closeTooltip = (): void => {
    this.tooltipController.closeTooltip()
  }

  onMessage = (data: Partial<IQuake>): void => {
    const ids = this.dataEntityList.map((quake) => quake.id)

    if (!ids.includes(data.id!)) {
      this.addMarker(data as IQuake)
    } else {
      this.updateMarker(data)
    }
  }

  private addMarker(newQuake: IQuake): void {
    const _newQuake = newQuake

    this.dataEntityList.push(_newQuake)
    this.createQuakeMarker(_newQuake)

    this._filter(_newQuake)

    this.alarmAnimations[_newQuake.id]?.mount(this.overlay as Element, this.preventCb as TPreventCb)
    this.elements[_newQuake.id].mount(this.mouseOverlay as Element, this.preventCb as TPreventCb)
  }

  private updateMarker(data: Partial<IQuake>): void {
    const targetIndex = this.dataEntityList.findIndex((quake) => data.id === quake.id)

    this.dataEntityList[targetIndex] = { ...this.dataEntityList[targetIndex], ...data }
    this.elements[data.id!].dataEntity = { ...this.elements[data.id!].dataEntity, ...data }

    if (this.tooltipController.id === data.id) this.tooltipController.updateTooltipData(data)

    this._filter(this.dataEntityList[targetIndex])

    if (this.tooltipController.id === data.id) this.tooltipController.updateTooltipData(data)

    if (data.magnitude) {
      this.elements[data.id!].setMagnitude(data.magnitude)
      this.alarmAnimations[data.id!]?.setMagnitude(data.magnitude)
    }

    if (data.isActive !== undefined) this.alarmAnimations[data.id!].setVisible(data.isActive)
  }
}
