import { BaseMapElement } from "../base"
import { theme } from "@constants"
import { getMagnitudeEValue, magColors } from "../../_utils"
import { IQuake } from "@types"
import { isToday } from "@utils"

export class QuakeMarker extends BaseMapElement<IQuake> {
  private magColor = ""
  private _isToday = false

  constructor(dataEntity: IQuake, private tooltipCb: (data: IQuake) => void) {
    super(dataEntity)

    this._isToday = isToday(this.dataEntity.time)
  }

  protected createElement(): HTMLDivElement {
    this._isToday = isToday(this.dataEntity.time)
    this.magColor = magColors[getMagnitudeEValue(this.dataEntity.magnitude)]

    const marker = document.createElement("div")
    this.setStyle(marker)

    marker.addEventListener("click", () => {
      this.openTooltip()
    })
    marker.addEventListener("mouseover", () => {
      marker.style.cursor = "pointer"
    })
    marker.addEventListener("mouseleave", () => {
      marker.style.cursor = "auto"
    })

    return marker
  }

  private setStyle(marker: HTMLDivElement): void {
    marker.style.position = "absolute"
    marker.style.width = "15px"
    marker.style.height = "15px"
    marker.style.borderRadius = "50%"
    marker.style.zIndex = "-1"

    this.setColor(marker)
  }

  private setColor(marker: HTMLDivElement): void {
    if (this._isToday) {
      marker.style.background = this.magColor
      marker.style.border = `3px solid ${theme.palette.secondary.main}`
      return
    }

    marker.style.border = `3px solid ${this.magColor}`
  }

  private openTooltip(): void {
    this.tooltipCb(this.dataEntity)
  }

  setMagnitude = (newValue: number): void => {
    this.dataEntity.magnitude = newValue
    this.magColor = magColors[getMagnitudeEValue(newValue)]

    this.setColor(this.element)
  }
}
